export const SET_BBOX = 'SET_BBOX';

export const setBbox = (bbox) => ({
  type: SET_BBOX,
  payload: bbox,
});

// store/map.js
export default function reducer(state = { bbox: null }, action) {
    switch (action.type) {
      // 기존 케이스들...
      case SET_BBOX:
        return { ...state, bbox: action.payload };
      default:
        return state;
    }
  }
